

import React from 'react';
import "./ClientReviews.css";
import quote1_black from "../../../assets/capeark_img/home/quote1_black.png"
import quote2_black from "../../../assets/capeark_img/home/quote2_black.png"
import Ankitgalaxyenterprise from "../../../assets/capeark_img/home/Author/Ankit-galaxyenterprise.jpeg"
function ClientReviews() {
    const handleWhatsAppClick = () => {
        const phoneNumber = '+918112450800';
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.location.href = whatsappUrl;
      };
    return (
        <>
            <section>
                <div className="container g-5">
                    <div className="author-container-main" style={{ margin: "70px 0" }}>
                        <div className="position-relative d-flex">
                            <div className="author-quote-content d-flex mb20">
                                <div className="quote-left position-absolute" style={{ left: 0, top: 0, opacity: "0.2", transform: "translate(-24px,-18px)" }}>
                                    <img src={quote1_black} alt="" style={{ width: "50px" }} /></div>
                                <div className="quote-text" style={{ width: "100%" }}>
                                    <p style={{ margin: "20px", textAlign: "center", fontSize: "16px", lineHeight: "150%", color: "#3b4351" }}>Capeark is really good at making software. They understand all the important parts of software like how it's built, how it looks, how people use it, and how to manage it. Nowadays, software can be quite complicated, and Capeark knows how to make it work smoothly. Working with them is easy, and I'm happy to recommend them highly.</p>
                                </div>
                                <div className="quote-right position-absolute" style={{ right: 0, bottom: 0, opacity: 0.2, transform: "translate(24px,18px)" }}>
                                    <img src={quote2_black} alt="" style={{ width: "50px", transform: "rotate(180deg)" }} />
                                </div>
                            </div>
                            <div className="d-flex" style={{ alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <div className="author-image-container mb-2" style={{ height: "100px", width: "100px", background: `50%/contain no-repeat url(${Ankitgalaxyenterprise})`, borderRadius: "50%", backgroundColor: "#94a9b3" }}></div>
                                <div className="author-name">
                                    <a href="/" rel="noreferrer" style={{ color: "#0064b7", fontSize: "16px" }} target="_blank">
                                        <strong onClick={handleWhatsAppClick}>Ankit</strong>
                                    </a>
                                </div>
                                <a href="https://www.galaxyenterprise.co" target="_blank" rel="noreferrer" className='author-address-cont' ><p>Galaxy Enterprise, Bhayandar, Mumbai</p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClientReviews
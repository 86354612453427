import React from 'react';
import "./css/AddressInfo.css";
import { Link } from 'react-router-dom';


function AddressInfo() {
    return (
        <React.Fragment>
            <div>
                <div className='container mt-5 mb-5 g-0'>
                    <div className='row g-0'>
                        <div className='col-sm-12 col-md-3 col-lg-3 g-0 mb-2 p-1'>
                            <div className='contact_address_card_cont text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                                <div className="map-icon">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className='contact_address_card_child_2'>
                                    <h3>Address</h3>
                                    <p className="">
                                        Gachibowli, Hyderabad – 500032, India.
                                        <br/>
                                        ( Our team is operating remotely )
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3 col-lg-3 mb-2 p-1'>
                            <div className='contact_address_card_cont text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                                <div className="map-icon">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className='contact_address_card_child_2'>
                                    <h3>Phone</h3>
                                    <p>Give us a ring on <Link to="tel:8112450800" className='contact_address_link'>+91 8112450800</Link>, <Link to="tel:8585946563" className='contact_address_link'>+91 8585946563</Link>. Our Experts are standing by monday to friday from 9am to 5pm EST.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3 col-lg-3 mb-2 p-1'>
                            <div className='contact_address_card_cont text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                                <div className="map-icon">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                                <div className='contact_address_card_child_2'>
                                    <h3>Email</h3>

                                    <p>Simple drop us an email at <Link to='javascript:void(0)' onClick={() => window.location = 'mailto:capeark.info@gmail.com'} className='contact_address_link'>capeark.info@gmail.com</Link> and you'll receive a reply within 24 hours</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3 col-lg-3 mb-2 p-1'>
                            <div className='contact_address_card_cont text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                                <div className="map-icon">
                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                </div>
                                <div className='contact_address_card_child_2'>
                                    <h3>Website</h3>
                                    <p>
                                        Visit Our website <Link to="http://capeark.com" target="_blank" className='contact_address_link'>www.capeark.com</Link>, for more information.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddressInfo